import { graphql } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import Layout from '../components/layout';
import Post from '../components/post';
import SEO from '../components/seo';
import { MetaData } from '../utils/globals';
import './styles.scss';
import Author from '../components/author';
import { Helmet } from "react-helmet"

const BlogPost = ({ pageContext, data: { allContentfulBlogs } }) => {
	const [ post ] = allContentfulBlogs.edges;
	const { next, previous } = pageContext;

	const option = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				console.log(node);
				const { data: { target: { sys: { id } } } } = node;
				const src = post.node.content.references.find((el: any) => el['contentful_id'] == id).fixed.src;
				return <img src={`https://${src}`} className="mt-4 mb-4" />;
			}
		}
	};

	return (
		<>
			<SEO
				title={post.node.title}
				description={post.node.abstract.abstract}
				keywords={MetaData.HOME.META.keywords}
				author={MetaData.HOME.META.author}
				lang={MetaData.HOME.META.lang}
				meta={MetaData.HOME.META.meta}
				image={MetaData.HOME.META.image}
				portada={MetaData.HOME.META.portada}
			/>
		<Layout>
			<section id="blog" className="blog-container">
				<div className="container">
					<div className="col-md-12 justify-content-center p-4">
						<h1 className="text-center">{post.node.title}</h1>
						<div className="row align-items-center">
							<div className="col">
								<p className="text-right">Escrito por</p>
							</div>
							<div className="col">
								<Author name={post.node.author.name} photo={post.node.author.photo.fluid.src} />
							</div>
						</div>
					</div>
					<img src={post.node.imageHeader.fluid.src} />
					<div className="col p-5">{documentToReactComponents(JSON.parse(post.node.content.raw), option)}</div>
					<div className="row">
						<div className="col-md-12">
							<h3>Otros Blogs</h3>
							<div className="row row-cols-1 row-cols-md-2 g-4">
								{!!previous && (
									<Post
										title={previous.title}
										photo={previous.imageHeader.fluid.src}
										abstract={previous.abstract.abstract}
										date={previous.date}
										slug={previous.slug}
										authorPhoto={previous.author.photo.fluid.src}
										authorName={previous.author.name}
									/>
								)}
								{!!next && (
									<Post
										slug={next.slug}
										title={next.title}
										photo={next.imageHeader.fluid.src}
										abstract={next.abstract.abstract}
										date={next.date}
										authorPhoto={next.author.photo.fluid.src}
										authorName={next.author.name}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
		<Helmet>
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/5117462.js"
                ></script>
           </Helmet>
		</>
	);
};

export default BlogPost;

export const pageQuery = graphql`
	query($slug: String!) {
		allContentfulBlogs(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					title
					slug
					abstract {
						abstract
					}
					imageHeader {
						fluid {
							src
						}
					}
					author {
						photo {
							fluid {
								src
							}
						}
						name
					}
					date(fromNow: true)
					content {
						raw
						references {
							... on ContentfulAsset {
								contentful_id
								fixed(width: 1600) {
									width
									height
									src
									srcSet
								}
							}
						}
					}
				}
			}
		}
	}
`;
